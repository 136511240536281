<template>
  <div class="page flex-col">
    <div class="container-body flex-col">
      <div  class="container">
      <!-- 中间部分标语 -->
      <div class="row block_3">
        <div class="col-sm-4">
          <p>
            <img class="image_2" referrerpolicy="no-referrer" src="../assets/img/left-decorative-horizontal-bar.png"/>
          </p>
        </div>
        <div class="col-sm-4">
          <p class="text_6">网络通信方案轻松设计</p>
        </div>
        <div class="col-sm-4">
          <p>
            <img class="image_2" referrerpolicy="no-referrer" src="../assets/img/right-decorative-horizontal-bar.png"/>
          </p>
        </div>
      </div>
      </div>
      <!-- 设计平台 -->
      <div class="block_1 flex-col">
        <div  class="container">
        <div class="block_4 flex-col">
          <div class="text-wrapper_2 flex-col"><span class="text_7">AI室分设计平台</span></div>

          <!-- 设计流程4列显示> -->
          <div class="services-block content content-center" id="services">
          <div class="container">
            <div class="row content-margin">
                    <div class="col-md-3 col-sm-3 col-xs-12 item">
                    <img src="../assets/img/design-process-1.png" alt="...">
                    <h3 class="text_8">建筑图纸智能识别</h3>
                    <p class="text_9">INTELLIGENT RECOGNITION OF<br>ARCHITECTURAL DRAWINGS</p>
                </div>
                <div class="col-md-3 col-sm-3 col-xs-12 item">
                    <img src="../assets/img/design-process-2.png" alt="...">
                    <h3 class="text_8">点位图 系统图<br>模测自动生成</h3>
                    <p>POINT BITMAP, SYSTEM DIAGRAM,<br>AUTOMATIC GENERATION OFMODEL TEST</p>
                </div>
                <div class="col-md-3 col-sm-3 col-xs-12 item">
                    <img src="../assets/img/design-process-3.png" alt="...">
                    <h3 class="text_8">设计方案 物料统计<br>概预算文本自动生成</h3>
                    <p>DESIGN SCHEME, MATERIAL STATISTICS,<br>BUDGET TEXT AUTOMATICALLY GENERATED</p>
                </div>
                <div class="col-md-3 col-sm-3 col-xs-12 item">
                    <img src="../assets/img/design-process-4.png" alt="...">
                    <h3 class="text_8">审计决算自动生成</h3>
                    <p class="text_9">AUTOMATIC GENERATION OF<br>AUDIT FINAL ACCOUNTS</p>
                </div>
            </div>
          </div>
          </div>
          <div class="flex-col">
            <p class="text_12">多种比选设计方案全套智能生成</p>
            <p class="text_13">A full set of comparison and selection design schemes is automatically generated</p>
          </div>
        </div>

         <!-- 动态咨询板块 -->
        <div class="block_4 flex-col">
          <div class="text-wrapper_2 flex-col"><span class="text_7">动态咨询</span></div>
          <div class="row info-row">
            <div class="col-lg-6">
              <div class="card img-fluid">
                <img class="card-img-top" src="../assets/img/zixun-recommend-1.png" alt="Card image" style="width:100%">
                <div class="card-img-overlay">
                  <h4 class="card-title text_16">中国5G发展现状与未来趋势</h4>
                  <p class="card-text text_17">我国移动通信技术起步虽晚，但在5G标准研发上正逐渐成为全球的领跑者</p>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="card img-fluid">
                <img class="card-img-top" src="../assets/img/zixun-recommend-2.png" alt="Card image" style="width:100%">
                <div class="card-img-overlay">
                  <h4 class="card-title text_16">AI辅助设计时代的三大设计应用思维</h4>
                  <p class="card-text text_17">大家不太适应直接看到方案被自动生成的感受，同时就生成方案能否直接使用的问题引发了不同层...的探讨</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 招贤纳士板块 -->
        <div class="block_4 flex-col block-recruit">
        <div class="text-wrapper_2 flex-col"><span class="text_7">招贤纳士</span></div>
        <!-- 表格样式 -->
        <div class="container">
          <table class="table" style="table-layout: fixed;">
            <thead class="thead-dark">
              <tr>
                <th class="th-itme" style="width:45%">招聘岗位</th>
                <th class="th-itme" style="width:45%">学历要求</th>
                <th style="width:10%"></th>
              </tr>
            </thead>      
            <tbody>
              <!-- 第一列 -->
              <tr v-if="(menuIndex1==0)">
                <td>
                  <div>
                    <img class="img-6" referrerpolicy="no-referrer" src="../assets/img/per-icon.png" />
                    <span class="text-group_6">计算机视觉工程师</span>
                  </div>
                </td>
                <td>
                  <div>
                    <img class="img-6" referrerpolicy="no-referrer" src="../assets/img/education-icon.png" />
                    <span class="text-group_6">硕士及以上</span>
                  </div>
                </td>
                <td>
                  <img class="lable-monse img-6" referrerpolicy="no-referrer" src="../assets/img/plus-open.png" 
                  @click="showMenu(1)"/>
                </td>
              </tr>
              <!-- 展开后列表样式 -->
              <tr v-if="menuIndex1==1">
                <td>
                  <div>
                    <img class="img-6" referrerpolicy="no-referrer" src="../assets/img/per-icon-open.png" />
                    <span class="text-group_10">计算机视觉工程师</span>
                  </div>
                </td>
                <td>
                  <div>
                    <img class="img-6" referrerpolicy="no-referrer" src="../assets/img/education-icon-open.png" />
                    <span class="text-group_10">硕士及以上</span>
                  </div>
                </td>
                <td>
                  <img class="lable-monse img-6" referrerpolicy="no-referrer" src="../assets/img/minus-stow.png" 
                  @click="showMenu(1)"/>
                </td>
              </tr>
               <!-- 展开后显示内容 -->
              <div class="text-wrapper_10 flex-col" v-if="menuIndex1==1">
                <h5 class="text_25">岗位要求：</h5>
                <p class="paragraph_7">1、参与图像处理、图像识别、目标检测、图像分割等计算机视觉算法研发工作</p>
                <p class="paragraph_7">2、解决计算机视觉与深度学习算法在实际场景中的优化问题</p>
                <h3 class="text_26">任职资格：</h3>
                <p class="paragraph_7">1、计算机、网络通信或相关专业，有计算机视觉或深度学习项目经验者优先</p>
                <p class="paragraph_7">2、熟悉常见的图像特征提取方法；熟悉常用的卷积神经网络框架、训练方法和调参方法</p>
                <p class="paragraph_7">3、有人体姿态识别、图像分割、人脸关键点识别等计算机视觉算法开发经验</p>
                <p class="paragraph_7">4、精通C/C++，Python等编程语言，熟悉基本的Linux指令</p>
                <p class="paragraph_7">5、熟悉TensorFlow机器学习框架、OpenCV图像处理库；能独立完成算法实验、开发和测试</p>
                <p class="paragraph_7 last_p">6、有图像识别应用开发项目经验者优先</p>
              </div>
              <!-- 第二列 -->
              <tr v-if="(menuIndex2==0)">
                <td>
                  <div>
                    <img class="img-6" referrerpolicy="no-referrer" src="../assets/img/per-icon.png" />
                    <span class="text-group_6">python开发工程师</span>
                  </div>
                </td>
                <td>
                  <div>
                    <img class="img-6" referrerpolicy="no-referrer" src="../assets/img/education-icon.png" />
                    <span class="text-group_6">硕士及以上</span>
                  </div>
                </td>
                <td>
                  <img class="lable-monse img-6" referrerpolicy="no-referrer" src="../assets/img/plus-open.png" 
                  @click="showMenu(2)"/>
                </td>
              </tr>
              <!-- 展开后列表样式 -->
              <tr v-if="menuIndex2==1">
                <td>
                  <div>
                    <img class="img-6" referrerpolicy="no-referrer" src="../assets/img/per-icon-open.png" />
                    <span class="text-group_10">python开发工程师</span>
                  </div>
                </td>
                <td>
                  <div>
                    <img class="img-6" referrerpolicy="no-referrer" src="../assets/img/education-icon-open.png" />
                    <span class="text-group_10">硕士及以上</span>
                  </div>
                </td>
                <td>
                  <img class="lable-monse img-6" referrerpolicy="no-referrer" src="../assets/img/minus-stow.png" 
                  @click="showMenu(2)"/>
                </td>
              </tr>
               <!-- 展开后显示内容 -->
              <div class="text-wrapper_10 flex-col" v-if="menuIndex2==1">
                <h5 class="text_25">岗位要求：</h5>
                <p class="paragraph_7">1、负责室分设计绘图模块的调度系统研发，参与底层框架和系统架构的建设</p>
                <p class="paragraph_7">2、负责系统的服务化部署与维护工作</p>
                <h3 class="text_26">任职资格：</h3>
                <p class="paragraph_7">1、熟悉数据结构与算法，包括常见数据结构（树、图、堆等）与常见设计模式</p>
                <p class="paragraph_7">2、熟练Python语⾔以及Django开发框架，有良好的编程规范</p>
                <p class="paragraph_7">3、能够对算法的执行的空间和时间效率进行分析并解决性能瓶颈，熟悉常用的 Python 性能调优工具</p>
                <p class="paragraph_7">4、熟练Linux、Docker以及⾃动化部署流程</p>
                <p class="paragraph_7">5、熟悉分布式系统和容器化调度，熟悉云原生系统搭建和开源生态，对 Kubernetes 和云原生开发有一定的经验。有一定的系统架构设计能力者优先</p>
                <p class="paragraph_7 last_p">6、逻辑清晰，专注技术有追求有热情，良好的团队合作精神及学习能⼒</p>
              </div>
               <!-- 第三列 -->
              <tr v-if="(menuIndex3==0)">
                <td>
                  <div>
                    <img class="img-6" referrerpolicy="no-referrer" src="../assets/img/per-icon.png" />
                    <span class="text-group_6">C/C++高级工程师</span>
                  </div>
                </td>
                <td>
                  <div>
                    <img class="img-6" referrerpolicy="no-referrer" src="../assets/img/education-icon.png" />
                    <span class="text-group_6">硕士及以上</span>
                  </div>
                </td>
                <td>
                  <img class="lable-monse img-6" referrerpolicy="no-referrer" src="../assets/img/plus-open.png" 
                  @click="showMenu(3)"/>
                </td>
              </tr>
              <!-- 展开后列表样式 -->
              <tr v-if="menuIndex3==1">
                <td>
                  <div>
                    <img class="img-6" referrerpolicy="no-referrer" src="../assets/img/per-icon-open.png" />
                    <span class="text-group_10">C/C++高级工程师</span>
                  </div>
                </td>
                <td>
                  <div>
                    <img class="img-6" referrerpolicy="no-referrer" src="../assets/img/education-icon-open.png" />
                    <span class="text-group_10">硕士及以上</span>
                  </div>
                </td>
                <td>
                  <img class="lable-monse img-6" referrerpolicy="no-referrer" src="../assets/img/minus-stow.png" 
                  @click="showMenu(3)"/>
                </td>
              </tr>
               <!-- 展开后显示内容 -->
              <div class="text-wrapper_10 flex-col" v-if="menuIndex3==1">
                <h5 class="text_25">岗位要求：</h5>
                <p class="paragraph_7">1、负责云端BIM建模引擎开发，包括BIM数据管理、构件几何造型、三维显示等核心功能开发</p>
                <p class="paragraph_7">2、根据项目需求，负责系统设计文档编写、编码、自测等开发任务</p>
                <h3 class="text_26">任职资格：</h3>
                <p class="paragraph_7">1、重点院校硕士及以上学历</p>
                <p class="paragraph_7">2、三年及以上建模平台和图形引擎开发经验，有CAD二次开发经验者优先</p>
                <p class="paragraph_7">3、精通C/C++编程，擅长嵌入式高性能多线程编程</p>
                <p class="paragraph_7">4、熟悉BIM/CAD软件设计，有ACIS、Parasolid、OpenCASCADE等几何造型引擎开发经验优先</p>
                <p class="paragraph_7">5、具备云化分布式设计和开发经验，对KVM、Docker、K8S有深入了解者优先</p>
                <p class="paragraph_7 last_p">6、具备网络通信相关领域测试/问题处理经验者优先</p>
              </div>
            </tbody>
        </table>
        </div>
      </div>
      <!-- 关于我们 -->
      <div class="block_4 flex-col">
        <div class="text-wrapper_2 flex-col"><span class="text_7">关于我们</span></div>
        <div class="text-wrapper_13">
          <p class="paragraph_9">
            天域智联（上海）人工智能科技有限责任公司
          </p>
          <p class="paragraph_10">
            一般项目：技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广；人工智能行业应用系统集成服务；
            人工智能应用软件开发；人工智能公共服务平台技术咨询服务；人工智能基础软件开发；人工智能理论与算法软件开发；
            人工智能通用应用系统；人工智能基础资源与技术平台；人工智能公共数据平台；信息系统集成服务；5G通信技术服务；
            物联网技术研发；物联网应用服务；智能控制系统集成；物联网技术服务；计算机系统服务；互联网数据服务；软件开发；
            工业互联网数据服务；大数据服务；数据处理服务；数据处理和存储支持服务；软件外包服务；网络与信息安全软件开发；
            卫星技术综合应用系统集成；信息技术咨询服务；专业设计服务；工程技术服务（规划管理、勘察、设计、监理除外）
            （除依法须经批准的项目外，凭营业执照依法自主开展经营活动）。许可项目：建筑智能化系统设计；建设工程勘察；
            建设工程设计（依法须经批准的项目，经相关部门批准后方可开展经营活动，具体经营项目以相关部门批准文件或许可证件为准）。
          </p>
        </div>
      </div>
      </div>
    </div>
  </div>
  </div>
</template>
<script>
export default {
  name: "HomeContainer",
  data() {
    return {
      menuIndex: 1,
      menuIndex1: 0,
      menuIndex2: 0,
      menuIndex3: 1,
      constants: {},
    };
  },
  created() {
    localStorage.setItem("menuIndex", this.menuIndex);
  },
  methods: {
    showMenu(index) {
      if(index==1){
        if(this.menuIndex1==0){
          this.menuIndex1 = 1;
        }else if(this.menuIndex1==1){
          this.menuIndex1 = 0; 
        }
      }

      if(index==2){
        if(this.menuIndex2==0){
          this.menuIndex2 = 1;
        }else if(this.menuIndex2==1){
          this.menuIndex2 = 0; 
        }
      }

      if(index==3){
        if(this.menuIndex3==0){
          this.menuIndex3 = 1;
        }else if(this.menuIndex3==1){
          this.menuIndex3 = 0; 
        }
      }

		},

  }
};
</script>
<style scoped lang="css" src="../assets/homeContainer.css" />